import * as intersector from "line-intersection";

function scalarMultiply(vector1, vector2) {
  return vector1.x * vector2.x + vector1.y * vector2.y;
}

function isPointOnRay(ray, point) {
  const firstVector = {
    x: ray.end.x - ray.start.x,
    y: ray.end.y - ray.start.y,
  };
  const secondVector = { x: point.x - ray.start.x, y: point.y - ray.start.y };
  const scalarMult = scalarMultiply(firstVector, secondVector);
  return scalarMult > 0;
}

export function getSegmentsIntersection(segment1, segment2) {
  const result = intersector.findSegmentIntersection([
    segment1.start,
    segment1.end,
    segment2.start,
    segment2.end,
  ]);
  return result;
}

export function getLinesIntersection(line1, line2) {
  const result = intersector.findIntersection([
    line1.start,
    line1.end,
    line2.start,
    line2.end,
  ]);
  return result;
}

export function getRaySegmentIntersection(ray, segment) {
  const result = intersector.findIntersection([
    ray.start,
    ray.end,
    segment.start,
    segment.end,
  ]);
  if (
    !result ||
    !intersector.isPointBetween(
      { x: result.x, y: result.y },
      segment.start,
      segment.end
    )
  ) {
    return;
  }
  if (isPointOnRay(ray, result)) {
    return result;
  }
}

export function getLineSegmentIntersection(line, segment) {
  const result = intersector.findIntersection([
    line.start,
    line.end,
    segment.start,
    segment.end,
  ]);
  if (
    intersector.isPointBetween(
      { x: Math.round(result.x), y: Math.round(result.y) },
      segment.start,
      segment.end
    )
  ) {
    return result;
  }
}

export function getRayIntersectionsWithScreenBounds(
  startPoint,
  rayPoint,
  width,
  height
) {
  const ray = {
    start: startPoint,
    end: rayPoint,
  };

  const intersections = [];
  let result = getRaySegmentIntersection(ray, {
    start: { x: 0, y: 0 },
    end: { x: width, y: 0 },
  });
  if (result) {
    intersections.push(result);
  }

  result = getRaySegmentIntersection(ray, {
    start: { x: width, y: 0 },
    end: { x: width, y: height },
  });
  if (result) {
    intersections.push(result);
  }

  result = getRaySegmentIntersection(ray, {
    start: { x: width, y: height },
    end: { x: 0, y: height },
  });
  if (result) {
    intersections.push(result);
  }

  result = getRaySegmentIntersection(ray, {
    start: { x: 0, y: height },
    end: { x: 0, y: 0 },
  });
  if (result) {
    intersections.push(result);
  }
  return intersections;
}

export function getLineIntersectionsWithScreenBounds(
  point1,
  point2,
  width,
  height
) {
  const line = {
    start: point1,
    end: point2,
  };

  const intersections = [];
  let result = getLineSegmentIntersection(line, {
    start: { x: 0, y: 0 },
    end: { x: width, y: 0 },
  });
  if (result) {
    intersections.push(result);
  }

  result = getLineSegmentIntersection(line, {
    start: { x: width, y: 0 },
    end: { x: width, y: height },
  });
  if (result) {
    intersections.push(result);
  }

  result = getLineSegmentIntersection(line, {
    start: { x: width, y: height },
    end: { x: 0, y: height },
  });
  if (result) {
    intersections.push(result);
  }

  result = getLineSegmentIntersection(line, {
    start: { x: 0, y: height },
    end: { x: 0, y: 0 },
  });
  if (result) {
    intersections.push(result);
  }
  return intersections;
}
