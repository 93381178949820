export const RUSSIAN_TRANSLATIONS = {
  dialog: {
    calibrationHeader: "Калибровка предустановки"
  },
  button: {
    cancel: "Отмена",
    save: "Сохранить",
    showGrid: "Показывать сетку"
  },
  rectangleHeight: "Ширина прямоугольника",
  rectangleWidth: "Длина прямоугольника"
};
