




import Vue from "vue";
import Calibration from "./components/Calibration.vue";
export default Vue.extend({
  name: "App",
  components: {
    Calibration
  },
  provide() {
    return {
      areas: this.$parent.areas,
      mountChildren: this.$parent.mountChildren
    };
  }
});
