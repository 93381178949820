<template>
  <v-stage ref="stage" :config="configKonva">
    <v-layer ref="calibrationLayer" />
  </v-stage>
</template>

<script>
import * as Konva from "konva";
import { ProjectionCalculator2d } from "projection-3d-2d";
import {
  getRayIntersectionsWithScreenBounds,
  getLinesIntersection,
} from "./boundsIntersector";

let projectionCalculator;
import Vue from "vue";
export default Vue.extend({
  props: {
    groundCalibration: Object,
    showGrid: Boolean,
    configKonva: Object,
  },
  data() {
    return {
      konvaGroup: new Konva.Group({}),
      stage: {},
      layer: undefined,
    };
  },
  watch: {
    showGrid() {
      this.draw();
    },
    groundCalibration: {
      deep: true,
      handler() {
        this.draw();
      },
    },
    configKonva: {
      deep: true,
      handler(val) {
        this.stage.width = val.width;
        this.stage.height = val.height;
        setTimeout(() => {
          this.draw();
        });
      },
    },
    "groundCalibration.rectangleWidth"() {
      this.calcMatrix();
    },
    "groundCalibration.rectangleHeight"() {
      this.calcMatrix();
    },
  },

  mounted() {
    this.stage = this.$refs.stage;
    this.calibrationLayer = this.$refs.calibrationLayer.getNode();
    this.calibrationLayer.add(this.konvaGroup);
    this.createProjectionCalculator();
    this.draw();
  },
  methods: {
    draw() {
      if (!this.calibrationLayer) {
        return;
      }
      this.konvaGroup.destroyChildren();

      const width = this.calibrationLayer.width();
      const height = this.calibrationLayer.height();

      if (this.showGrid) {
        this.drawGrid(width, height);
      }

      const pointsForLines = this.groundCalibration.rectanglePoints.reduce(
        (acc, point) => {
          acc.push(point.x * width, point.y * height);
          return acc;
        },
        []
      );
      const lines = new Konva.Line({
        points: pointsForLines,
        stroke: "red",
        closed: true,
        opacity: 1,
      });
      this.konvaGroup.add(lines);
      const text1 = new Konva.Text({
        text: this.groundCalibration.rectangleWidth + " м",
        x:
          (width *
            (this.groundCalibration.rectanglePoints[0].x +
              this.groundCalibration.rectanglePoints[1].x)) /
          2,
        y:
          (height *
            (this.groundCalibration.rectanglePoints[0].y +
              this.groundCalibration.rectanglePoints[1].y)) /
          2,
        fontSize: this.configKonva.height / 30,
        fill: "#dcdfe5",
        align: "center",
        verticalAlign: "middle",
      });
      const text2 = new Konva.Text({
        text: this.groundCalibration.rectangleWidth + " м",
        x:
          (width *
            (this.groundCalibration.rectanglePoints[2].x +
              this.groundCalibration.rectanglePoints[3].x)) /
          2,
        y:
          (height *
            (this.groundCalibration.rectanglePoints[2].y +
              this.groundCalibration.rectanglePoints[3].y)) /
          2,
        fontSize: this.configKonva.height / 30,
        fill: "#dcdfe5",
        align: "center",
        verticalAlign: "middle",
      });
      const text3 = new Konva.Text({
        text: this.groundCalibration.rectangleHeight + " м",
        x:
          (width *
            (this.groundCalibration.rectanglePoints[1].x +
              this.groundCalibration.rectanglePoints[2].x)) /
          2,
        y:
          (height *
            (this.groundCalibration.rectanglePoints[1].y +
              this.groundCalibration.rectanglePoints[2].y)) /
          2,
        fontSize: this.configKonva.height / 30,
        fill: "#dcdfe5",
        align: "center",
        verticalAlign: "middle",
      });
      const text4 = new Konva.Text({
        text: this.groundCalibration.rectangleHeight + " м",
        x:
          (width *
            (this.groundCalibration.rectanglePoints[0].x +
              this.groundCalibration.rectanglePoints[3].x)) /
          2,
        y:
          (height *
            (this.groundCalibration.rectanglePoints[0].y +
              this.groundCalibration.rectanglePoints[3].y)) /
          2,
        fontSize: this.configKonva.height / 30,
        fill: "#dcdfe5",
        align: "center",
        verticalAlign: "middle",
      });
      this.konvaGroup.add(text1);
      this.konvaGroup.add(text2);
      this.konvaGroup.add(text3);
      this.konvaGroup.add(text4);

      const rectPoints = this.groundCalibration.rectanglePoints;
      rectPoints.forEach((point, pointIndex) => {
        const rectPoint = new Konva.Circle({
          radius: 8,
          fill: "orange",
          stroke: "black",
          strokeWidth: 1,
          draggable: true,
          x: point.x * width,
          y: point.y * height,
        });
        rectPoint.on("mouseover", () => {
          rectPoint.fill("yellow");
          rectPoint.stroke("black");
          rectPoint.getLayer().draw();
        });
        rectPoint.on("mouseout", () => {
          rectPoint.fill("orange");
          rectPoint.stroke("black");
          rectPoint.getLayer().draw();
        });
        rectPoint.on("dragend", (data) => {
          let rect = data.evt.target.getBoundingClientRect();
          const newPoint = {
            x: (data.evt.clientX - rect.left) / rect.width,
            y: (data.evt.clientY - rect.top) / rect.height,
          };
          this.groundCalibration.rectanglePoints[pointIndex] = newPoint;
          this.calcMatrix();
        });

        this.konvaGroup.add(rectPoint);
      });

      this.calibrationLayer.draw();
    },
    drawGrid(width, height) {
      function calculateGridLines(
        projectionCalculator,
        vanishingPoints,
        videoWidth,
        videoHeight
      ) {
        const lines = [];

        const k =
          (vanishingPoints[0][1] - vanishingPoints[1][1]) /
          (vanishingPoints[0][0] - vanishingPoints[1][0]);
        const b = vanishingPoints[0][1] - k * vanishingPoints[0][0];

        const stepInMeters = 5;
        const boundsInMeters = 100;
        // Берем точку схода 1
        for (let i = -boundsInMeters; i <= boundsInMeters; i += stepInMeters) {
          let point3d = { x: i, y: 0 };
          addLineToResult(vanishingPoints[0], point3d);
        }

        for (let i = -boundsInMeters; i <= boundsInMeters; i += stepInMeters) {
          let point3d = { x: 0, y: i };
          addLineToResult(vanishingPoints[1], point3d);
        }
        return lines;

        function addLineToResult(vanishingPoint, point3d) {
          const point2d = projectionCalculator.getProjectedPoint([
            point3d.x,
            point3d.y,
          ]);
          if (point2d[1] < point2d[0] * k + b) {
            return;
          }
          const intersections = getRayIntersectionsWithScreenBounds(
            { x: vanishingPoint[0], y: vanishingPoint[1] },
            { x: point2d[0], y: point2d[1] },
            videoWidth,
            videoHeight
          );
          if (intersections.length == 1) {
            const point1_resized = {
              x: vanishingPoint[0],
              y: vanishingPoint[1],
            };
            const point2_resized = intersections[0];
            lines.push({ start: point1_resized, end: point2_resized });
          } else if (intersections.length == 2) {
            const point2_resized = intersections[0];
            const point1_resized = intersections[1];
            lines.push({ start: point1_resized, end: point2_resized });
          }
        }
      }

      const drawLine = (layer, point1, point2) => {
        let newLine = new Konva.Line({
          points: [
            point1.x * width,
            point1.y * height,
            point2.x * width,
            point2.y * height,
          ],
          stroke: "orange",
          strokeWidth: 1,
          visible: true,
          opacity: 0.5,
        });
        layer.add(newLine);
      };

      const rectPoints = this.groundCalibration.rectanglePoints;
      const vanishingPoint1 = getLinesIntersection(
        { start: rectPoints[1], end: rectPoints[2] },
        { start: rectPoints[0], end: rectPoints[3] }
      );
      const vanishingPoint2 = getLinesIntersection(
        { start: rectPoints[0], end: rectPoints[1] },
        { start: rectPoints[2], end: rectPoints[3] }
      );
      const vanishingPoints = [
        [vanishingPoint1.x, vanishingPoint1.y],
        [vanishingPoint2.x, vanishingPoint2.y],
      ];
      const gridLines = calculateGridLines(
        projectionCalculator,
        vanishingPoints,
        1,
        1
      );
      gridLines.forEach((line) => {
        drawLine(this.konvaGroup, line.start, line.end);
      });
    },
    createProjectionCalculator() {
      const width = this.groundCalibration.rectangleWidth;
      const height = this.groundCalibration.rectangleHeight;
      const points3d = [
        [0, 0],
        [width, 0],
        [width, height],
        [0, height],
      ];

      const rectPoints = this.groundCalibration.rectanglePoints;
      const points2d = [
        [rectPoints[0].x, rectPoints[0].y],
        [rectPoints[1].x, rectPoints[1].y],
        [rectPoints[2].x, rectPoints[2].y],
        [rectPoints[3].x, rectPoints[3].y],
      ];
      projectionCalculator = new ProjectionCalculator2d(
        points3d,
        points2d,
        1,
        1
      );
      return projectionCalculator;
    },
    calcMatrix() {
      this.createProjectionCalculator();
      const matrixToSave = [];
      matrixToSave.push(projectionCalculator.resultMatrixInversed[0][0]);
      matrixToSave.push(projectionCalculator.resultMatrixInversed[0][1]);
      matrixToSave.push(projectionCalculator.resultMatrixInversed[0][2]);
      matrixToSave.push(projectionCalculator.resultMatrixInversed[1][0]);
      matrixToSave.push(projectionCalculator.resultMatrixInversed[1][1]);
      matrixToSave.push(projectionCalculator.resultMatrixInversed[1][2]);
      matrixToSave.push(projectionCalculator.resultMatrixInversed[2][0]);
      matrixToSave.push(projectionCalculator.resultMatrixInversed[2][1]);
      matrixToSave.push(projectionCalculator.resultMatrixInversed[2][2]);
      this.groundCalibration.projectionMatrix = matrixToSave;
    },
  },
});
</script>

<style></style>
