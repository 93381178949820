





import { getServerContext, getFiwareHeaders } from "@netvision/lib-api-gateway";
import Vue from "vue";

export default Vue.extend({
  name: "PresetImage",
  props: {
    presetId: String
  },
  data() {
    return {
      src: "" as string,
      cancelLoad: null as null | (() => void)
    };
  },
  inject: ["areas", "mountChildren"],
  methods: {
    setSrc(blob: Blob) {
      this.src = window.URL.createObjectURL(blob);
    }
  },
  watch: {
    presetId(val) {
      this.cancelLoad?.();
      this.cancelLoad = loadPreviewEffect(val, blob => {
        this.setSrc(blob);
        this.cancelLoad = null;
      });
    },
    src(val, oldVal) {
      if (oldVal) {
        window.URL.revokeObjectURL(oldVal);
      }
    }
  },
  mounted() {
    this.cancelLoad = loadPreviewEffect(this.presetId, blob => {
      this.setSrc(blob);
      this.cancelLoad = null;
    });
  },
  destroyed() {
    this.cancelLoad?.();
  }
});

function buildPresetPreviewUrl(presetId: string) {
  return `${getServerContext().gatewayPath}/cameras/v1/presets/${presetId}`;
}

function fetchPosterVideo(posterUrl: string, signal?: AbortSignal) {
  return fetch(posterUrl, {
    headers: getFiwareHeaders(),
    signal
  }).then(res => {
    if (
      res.status === 200
      // TODO enable when backed will send proper content type
      // && res.headers.get('Content-Type') === 'video/mp4'
    ) {
      return res.blob();
    } else {
      throw Error("Preview cannot be fetched");
    }
  });
}

function loadPreviewEffect(
  presetId: string,
  onLoad: (blob: Blob) => void
): () => void {
  const previewUrl = buildPresetPreviewUrl(presetId);
  const controller = new AbortController();
  const signal = controller.signal;
  fetchPosterVideo(previewUrl, signal)
    .catch(e => {
      console.error(e);
      return null;
    })
    .then(blob => {
      if (!signal.aborted && blob) {
        onLoad(blob);
      }
    });

  return () => {
    controller.abort();
  };
}
