
































































import Vue from "vue";
import { createCamerasConnection } from "@netvision/lib-api-gateway";
import CameraBindingMode from "./CameraBindingMode.vue";
import PresetImage from "./PresetImage.vue";
import { name as appName } from "../../package.json";
export default Vue.extend({
  name: "Calibration",
  props: {
    preset: Object as () => CameraPreset,
    onClose: (Function as unknown) as () => () => void
  },
  components: {
    CameraBindingMode,
    PresetImage
  },
  data() {
    return {
      showGrid: true,
      appName,
      configKonva: {
        width: 100,
        height: 100
      },
      groundCalibration: {
        rectangleHeight: 10,
        rectangleWidth: 10,
        projectionMatrix: [],
        rectanglePoints: [
          { x: 0.33, y: 0.25 },
          { x: 0.66, y: 0.25 },
          { x: 0.8, y: 0.8 },
          { x: 0.2, y: 0.81 }
        ]
      } as GroundCalibration
    };
  },
  methods: {
    openDialog() {
      this.groundCalibration =
        this.preset.groundCalibration &&
        this.preset.groundCalibration.rectanglePoints
          ? JSON.parse(JSON.stringify(this.preset.groundCalibration))
          : this.groundCalibration;
    },
    saveCalibration(): Promise<void> {
      const connection = createCamerasConnection();
      return connection.v2
        .updateEntityAttributes(
          {
            type: "CameraPreset",
            id: this.preset.id,
            groundCalibration: this.groundCalibration
          },
          {
            keyValues: true
          }
        )
        .then(() => {
          this.onClose();
        })
        .catch(this.errorToast);
    },
    onResize() {
      const preview = this.$refs.preview as HTMLElement;
      if (preview) {
        this.configKonva.width = preview.clientWidth;
        this.configKonva.height = preview.clientHeight;
      }
    },
    errorToast(error: any) {
      console.error(error);
      this.$toast.add({
        severity: "error",
        summary: this.$t("error"),
        detail: error.message,
        life: 5000
      });
    }
  },
  computed: {
    presetId: function(): string | undefined {
      return this.preset?.id;
    }
  },
  mounted() {
    this.openDialog();
  },
  beforeDestroy() {}
});
