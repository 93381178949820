import "./set-public-path";
import Vue from "vue";
import singleSpaVue from "single-spa-vue";
import Toast from "primevue/toast";
Vue.component("Toast", Toast);
import ToastService from "primevue/toastservice";
Vue.use(ToastService);
import Button from "primevue/button";
Vue.component("Button", Button);
import Checkbox from "primevue/checkbox";
Vue.component("Checkbox", Checkbox);
import InputNumber from "primevue/inputnumber";
Vue.component("InputNumber", InputNumber);
import Dialog from "primevue/dialog";
Vue.component("Dialog", Dialog);

//@ts-ignore
import VueResizeObserver from "vue-resize-observer";
Vue.use(VueResizeObserver);

import VueI18n from "vue-i18n";
import { RUSSIAN_TRANSLATIONS } from "./translations/ru";
import VueKonva from "vue-konva";
Vue.use(VueKonva);

import App from "./App.vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
Vue.use(VueI18n);

Vue.config.productionTip = false;

const routes = [] as any;
const router = new VueRouter({
  base: __dirname,
  mode: "history",
  routes,
});
const locale = "ru";
const TRANSLATIONS = {
  ru: RUSSIAN_TRANSLATIONS,
};
const i18n = new VueI18n({
  locale,
  messages: TRANSLATIONS,
});

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    router,
    i18n,
    render: (h: any) => h(App),
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = (props: any) => {
  if (props.domElement && props.vueAppend) {
    const el = document.createElement("div");
    props.domElement.appendChild(el);
    props.domElement = el;
  }
  return vueLifecycles.mount(props);
};
export const unmount = vueLifecycles.unmount;
