(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("single-spa-vue"), require("vue-router"), require("primevue/toastservice"), require("vue"), require("primevue/inputnumber"), require("@netvision/lib-api-gateway"), require("primevue/checkbox"), require("primevue/toast"), require("primevue/dialog"), require("primevue/button"));
	else if(typeof define === 'function' && define.amd)
		define(["single-spa-vue", "vue-router", "primevue/toastservice", "vue", "primevue/inputnumber", "@netvision/lib-api-gateway", "primevue/checkbox", "primevue/toast", "primevue/dialog", "primevue/button"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("single-spa-vue"), require("vue-router"), require("primevue/toastservice"), require("vue"), require("primevue/inputnumber"), require("@netvision/lib-api-gateway"), require("primevue/checkbox"), require("primevue/toast"), require("primevue/dialog"), require("primevue/button")) : factory(root["single-spa-vue"], root["vue-router"], root["primevue/toastservice"], root["vue"], root["primevue/inputnumber"], root["@netvision/lib-api-gateway"], root["primevue/checkbox"], root["primevue/toast"], root["primevue/dialog"], root["primevue/button"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__4171__, __WEBPACK_EXTERNAL_MODULE__6389__, __WEBPACK_EXTERNAL_MODULE__68f9__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE__938d__, __WEBPACK_EXTERNAL_MODULE_bb6b__, __WEBPACK_EXTERNAL_MODULE_bc44__, __WEBPACK_EXTERNAL_MODULE_d26e__, __WEBPACK_EXTERNAL_MODULE_dec9__, __WEBPACK_EXTERNAL_MODULE_f095__) {
return 